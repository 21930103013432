import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Form,
  Spinner
} from "reactstrap"
import DueDatePicker from "components/Common/DueDatePicker"
import RepeatOptions from "components/Common/RepeatOptions"
import ReminderDatePickerV2 from "components/Common/ReminderDatePickerV2"

import CreatableSelect from 'react-select/creatable'
import { showSuccessToast } from "helpers/function_helper"
import { addConfigs } from "helpers/backend_helper"
import { getConfigCache } from "helpers/cache_helper"
import { addTask } from "helpers/backend_helper"
import { editTask } from "helpers/backend_helper"

import { isNull } from "lodash";

const NewTask = props => {

  const [title, setTitle] = useState('')
  const [priority, setPriority] = useState('N')
  const [newTaskModal, setNewTaskModal] = useState(false)
  const [finalDueDate, setFinalDueDate] = useState()
  const [repeatOption, setRepeatOption] = useState()
  const [finalReminderDate, setFinalReminderDate] = useState()
  
  const [selectedTags, setSelectedTags] = useState([])
  const [currentTags, setCurrentTags] = useState([])
  //const [rawTags, setRawTags] = useState([])
  const [defaultTag, setDefaultTag] = useState()
  const [tagsLoading, setTagsLoading] = useState(false)

  const [defaultCategory, setDefaultCategory] = useState()
  const [categoryLoading, setCategoryLoading] = useState(false)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [addInProgress, setAddInProgress] = useState(false)

  useEffect(()=>{
    setNewTaskModal(props.clickAddNewTask)
    //console.log('props.clickAddNewTask',props.clickAddNewTask)
    //console.log('props.editMode',props.editMode)
    //console.log('props.taskToEdit',props.taskToEdit)
    if(props.clickAddNewTask && props.editMode){
      setTitle(props.taskToEdit.title)
      setPriority(props.taskToEdit.priority)
      setFinalDueDate(props.taskToEdit.due)
      setRepeatOption(props.taskToEdit.rpt)
      setFinalReminderDate(props.taskToEdit.remnds)

      if(props.taskToEdit.tags && props.taskToEdit.tags.length > 0){
        const selTags = []
        props.taskToEdit.tags.forEach(eachTag => {
          const foundTag = currentTags.filter(each=> each.value==eachTag)
          if(foundTag && foundTag.length ==1){
            selTags.push(foundTag[0])
          }
        })
        setSelectedTags(selTags)
      }

      if(props.taskToEdit.cat){
        const newCategory = categoryOptions.filter(each => each.value == props.taskToEdit.cat)
        //console.log('newCategory:'+JSON.stringify(newCategory))
        if(newCategory && newCategory.length==1){
          setSelectedCategory(newCategory[0])
        }
      }
    }
  },[props.clickAddNewTask])
  
  useEffect(()=>{
    getTags(true)
    getCategories(true)
    //const tags = ['Hri','Kan','Vai']
    //addTags(tags)
  },[])

  useEffect(()=>{
    if(defaultTag){
      const newTag = currentTags.filter(each => each.value == defaultTag)
      //console.log('selectedTag:'+JSON.stringify(newTag))
      if(newTag && newTag.length==1){
        let selectedTagsTemp = [...selectedTags]
        //console.log('selectedTagsTemp:'+JSON.stringify(selectedTagsTemp))
        selectedTagsTemp.push(newTag[0])
        setSelectedTags(selectedTagsTemp)
        setDefaultTag()
      }
    }
  },[currentTags])

  useEffect(()=>{
    if(defaultCategory){
      const newCategory = categoryOptions.filter(each => each.value == defaultCategory)
      //console.log('newCategory:'+JSON.stringify(newCategory))
      if(newCategory && newCategory.length==1){
        setSelectedCategory(newCategory[0])
        setDefaultCategory()
      }
    }
  },[categoryOptions])

  function closeModal(newValueAdded){
    resetForm()
    setNewTaskModal(!newTaskModal)
    if(newTaskModal){
      props.closeNewTask(newValueAdded)
    }
  }
  function resetForm(){
    setTitle('')
    setPriority('N')
    setFinalDueDate()
    setRepeatOption()
    setFinalReminderDate()
    setSelectedTags([])
    setSelectedCategory()
  }

  async function getTags(fromCache){
    try {
      const resp = await getConfigCache('tags',fromCache)
      if(resp){
        //setRawTags(resp)
        setCurrentTags(buildOptions((resp)))
      }
      setTagsLoading(false)
    } catch (error) {
      setTagsLoading(false)
      console.log('error:',error)
    }
  }

  async function getCategories(fromCache){
    try {
      const resp = await getConfigCache('categories',fromCache)
      //console.log('categories get resp:',resp)
      if(resp){
        setCategoryOptions(buildOptions((resp)))
      }
      setCategoryLoading(false)
    } catch (error) {
      setCategoryLoading(false)
      console.log('cat error:',error)
    }
  }

  function addNewTag(tag){
    addTags(tag)
  }

  function addTags(tags){
    setTagsLoading(true)
    try {
      addConfigs({
        "key":"tags",
        "data": tags
      }).then(resp =>{
        getTags(false)
        //console.log('addTags resp:',resp)
      })
    } catch (error) {
      setTagsLoading(false)
      console.log('error:',error)
    }
  }

  function addNewCategory(category){
    setCategoryLoading(true)
    try {
      addConfigs({
        "key":"categories",
        "data": category
      }).then(resp =>{
        getCategories(false)
        //console.log('addTags resp:',resp)
      })
    } catch (error) {
      setCategoryLoading(false)
      console.log('error:',error)
    }
  }

  function buildOptions(array){
    let finalArray = []
    array.forEach(each => {
      finalArray.push({
        label: each, 
        value: each
      })
    })
    return finalArray
  }

  const [validation, setValidation] = useState({
    title: null
  })

  const onConfirmDueDate = (date) => {
    setFinalDueDate(date)
  }

  const onConfirmRepeatOption = (option) => {
    setRepeatOption(option)
  }
  

  const onConfirmReminderDate = (date) => {
    setFinalReminderDate(date)
  }

  function handleMultiTags(selectedMulti) {
    //console.log('selectedTags:'+selectedTags)
    setSelectedTags(selectedMulti)
  }

  function handleCategory(selectedCat) {
    setSelectedCategory(selectedCat)
  }

  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation }
    if (value !== "") {
      modifiedV[fieldName] = true
    } else {
      modifiedV[fieldName] = false
    }
    setValidation(modifiedV)
  }

  function handleSubmit(){
    const modifiedV = { ...validation }
    let validForm = false
    if (title === "") {
      modifiedV["title"] = false
    } else {
      modifiedV["title"] = true
      validForm = true
    }
    setValidation(modifiedV)

    if(validForm){

      setAddInProgress(true)

      let tempCategory;
      if(selectedCategory == undefined ||  isNull(selectedCategory) || selectedCategory == ''){
        //tempCategory = 'D'
      }
      else{
        tempCategory = selectedCategory.value
      }

      let tempTags;
      if(selectedTags && selectedTags.length > 0){
        tempTags = []
        selectedTags.forEach(each => {
          tempTags.push(each.value)
        })
      }
      const input= {
        id: props.editMode ? props.taskToEdit.id : Date.now(),
        title : title,
        due : finalDueDate,
        rpt : repeatOption,
        remnds : finalReminderDate,
        priority : priority,
        tags: tempTags,
        cat : tempCategory,
        status : 'P'
      }
      //console.log('Record to create:'+JSON.stringify(input))
      if(props.editMode){
        editTask(input)
        .then(resp=>{
          //console.log('resp::'+JSON.stringify(resp))
          if(resp && resp.Message && resp.Message=='SUCCESS'){
            showSuccessToast('Task updated successfully')
            closeModal(true)
            /*
            setNewTaskModal(!newTaskModal)
            if(newTaskModal){
              props.closeNewTask(true)
            }*/
          }
        })
        .finally(() => {
          setAddInProgress(false)
        })
      }
      else{
        addTask(input)
        .then(resp=>{
          //console.log('resp::'+JSON.stringify(resp))
          if(resp && resp.Message && resp.Message=='SUCCESS'){
            showSuccessToast('Task added successfully')
            closeModal(true)
            /*
            setNewTaskModal(!newTaskModal)
            if(newTaskModal){
              props.closeNewTask(true)
            }*/
          }
        })
        .finally(() => {
          setAddInProgress(false)
        })
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={newTaskModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="newTaskModal"
        tabIndex="-1"
        toggle={() => {
          closeModal(false)
          /*
          setNewTaskModal(!newTaskModal)
          if(newTaskModal){
            props.closeNewTask(false)
          }*/
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              closeModal(false)
              /*
              setNewTaskModal(!newTaskModal)
              if(newTaskModal){
                props.closeNewTask(false)
              }*/
            }}
          >
            {props.editMode ? "Edit Task" : "New Task"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row className="d-flex align-items-center justify-content-center ">
                <Col xs="1" className="mb-3">
                  <i className="bx bx-shape-polygon font-size-18 text-info" />
                </Col>
                <Col xs="11">
                  <div className="mb-3">
                    <Input value={title || ''} type="text" className="form-control border-bottom" id="tasktitleid" placeholder="Task Title" autoComplete="new-password" 
                    onChange={event => {
                      onChangeValidation("title", event.target.value)
                      setTitle(event.target.value)
                    }}
                    />
                    {validation["title"] == false ? <small className="error ms-2">Please enter a title</small> : null}

                  </div>
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center mb-3">
                <Col xs="1" className="">
                  <i className="bx bx-calendar font-size-18 text-info" />
                </Col>
                <Col xs="11">
                  <DueDatePicker 
                    onConfirmDueDate={onConfirmDueDate}
                    dueDate={finalDueDate}
                    editMode={props.editMode}
                    /> 
                </Col>
              </Row>
              {finalDueDate && <Row className="d-flex align-items-center justify-content-center mb-3">
                <Col xs="1" className="">
                  <i className="bx bx-repeat font-size-18 text-info" />
                </Col>
                <Col xs="11">
                  <RepeatOptions 
                    onConfirmRepeatOption={onConfirmRepeatOption}
                    repeatOption={repeatOption}
                    editMode={props.editMode}
                    /> 
                </Col>
              </Row>}
              <Row className="d-flex align-items-center justify-content-center mb-3">
                <Col xs="1" className="">
                  <i className="bx bxs-bell font-size-18 text-info" />
                </Col>
                <Col xs="11">
                  <ReminderDatePickerV2 
                    onConfirmReminderDate={onConfirmReminderDate}
                    dueDate={finalDueDate}
                    reminderDate={finalReminderDate}
                    editMode={props.editMode}
                    /> 
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center mb-3">
                <Col xs="1" className="">
                  <i className="bx bxs-flag font-size-18 text-info" />
                </Col>
                <Col xs="11" className="d-flex">
                  <div className="form-check form-radio-warning">
                    <input
                      type="radio"
                      id="customRadiocolor1"
                      name="customRadiocolor1"
                      className="form-check-input"
                      checked={priority==='N'}
                      value="N"
                      onChange={event => {
                        setPriority(event.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customRadiocolor1"
                    >
                      Normal
                    </label>
                  </div>
                  <div className="ms-2 form-check form-radio-danger">
                    <input
                      type="radio"
                      id="customRadiocolor2"
                      name="customRadiocolor1"
                      className="form-check-input"
                      value="H"
                      checked={priority==='H'}
                      onChange={event => {
                        setPriority(event.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customRadiocolor2"
                    >
                      High
                    </label>
                  </div>
                  <div className="ms-2 form-check form-radio-success">
                    <input
                      type="radio"
                      id="customRadiocolor3"
                      name="customRadiocolor1"
                      className="form-check-input"
                      value="L"
                      checked={priority==='L'}
                      onChange={event => {
                        setPriority(event.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customRadiocolor3"
                    >
                      Low
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center mb-3">
                <Col xs="1" className="">
                  <i className="bx bxs-tag font-size-18 text-info" />
                </Col>
                <Col xs="11">
                  <CreatableSelect
                    placeholder="Tags"
                    value={selectedTags}
                    onChange={(e) => {
                      //console.log('e::'+JSON.stringify(e))
                      handleMultiTags(e)
                    }}
                    options={currentTags}
                    classNamePrefix="select2-selection"
                    isLoading={tagsLoading}
                    isMulti={true}
                    id="productcategory"
                    onCreateOption={(e) => {
                      addNewTag(e)
                      setDefaultTag(e)
                      //setEnteredText(e)
                      //addNewItem('Category')
                    }}
                  />
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center mb-3 select2-container">
                <Col xs="1" className="">
                  <i className="bx bx-door-open font-size-18 text-info" />
                </Col>
                <Col xs="11">
                  <CreatableSelect
                    placeholder="Category"
                    value={selectedCategory}
                    onChange={(e) => {
                      handleCategory(e)
                    }}
                    isClearable={true}
                    options={categoryOptions}
                    classNamePrefix="select2-selection"
                    isLoading={categoryLoading}
                    id="category"
                    onCreateOption={(e) => {
                      setDefaultCategory(e)
                      addNewCategory(e)
                      //setEnteredText(e)
                      //addNewItem('Category')
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            {addInProgress && <Spinner style={{ width: '2.0rem', height: '2.0rem' }} className="ms-3" color="info" /> }
            <Button
              type="button"
              color="secondary"
              className="btn btn-secondary w-md mt-2"
              onClick={() => {
                closeModal(false)
                /*
                setNewTaskModal(!newTaskModal)
                if(newTaskModal){
                  props.closeNewTask(false)
                }*/
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              color="primary"
              className="btn btn-primary w-md mt-2"
              disabled={addInProgress}
              onClick={() => {
                handleSubmit()
              }}
            >
              {props.editMode ? " UPDATE ": " SAVE "}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default NewTask