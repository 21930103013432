import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
 } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { repOptions } from "common/data/tasks"

const RepeatOptions = props => {

  const [menu, setMenu] = useState(false)
  const [selOption, setSelOption] = useState('Never')
  const [repeatOption, setRepeatOption] = useState('NR')
  
  
  useEffect(()=>{
    //console.log('props.dueDate',props.dueDate)
    //console.log('props.editMode',props.editMode)
    if(props.repeatOption && props.editMode){
      const foundOption = repOptions.filter(each => each.value == props.repeatOption)
      if(foundOption && foundOption.length==1){
        setRepeatOption(foundOption[0].value)
        setSelOption(foundOption[0].label)
      }
    }
  },[props.repeatOption])

  function setSelectedOption(item){
    setSelOption(item.label)
    setRepeatOption(item.value)
    setMenu(!menu)
    props.onConfirmRepeatOption(item.value)
  }

  return (
    <React.Fragment>
      <Dropdown
          className=""
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          disabled={false}
        >
          <DropdownToggle className="btn btn-light d-flex align-items-center justify-content-center ">
            <span className={"text-dark"}>Repeat :</span>
            <span className={"text-primary ms-1"}>
             {selOption}
            </span>
          </DropdownToggle>
          <Row>
            <Col>
              <DropdownMenu className="dropdown-megamenu no-mobile-padding">
                <Row className="mb-1 mx-1">
                  {repOptions.map((each, idx)=>(
                    <Col key={idx} xs={6} className={classNames({"bg-light-blue": repeatOption == each.value},"py-2 text-center")}>
                      <Link to="#" 
                        className="off-dark "
                        onClick={() => {
                          setSelectedOption(each)
                        }}>
                          {each.label}
                      </Link>
                    </Col>
                  ))}
                </Row>
              </DropdownMenu>
            </Col>
          </Row>
        </Dropdown>
      </React.Fragment>
  )
}

RepeatOptions.propTypes = {
  onConfirmRepeatOption : PropTypes.func
}

export default RepeatOptions
