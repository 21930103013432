import { func } from "prop-types"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as moment from "moment"
import Storage from "@aws-amplify/storage";
import {UPLOAD_PREFIX_FOLDER} from "./url_helper"
import imageCompression from 'browser-image-compression';

export const showNoDataIndication = (isLoading) => {
    if(isLoading){
      return (
        <div className="text-center my-3">
          <div className="text-success">
            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
            Loading...
          </div>
        </div>
      )
    }
    else{
      return <div className="text-center my-3">- No transactions found -</div>
    }
}

export function getNextSeries(inputDate, seriesType){
  
  let beforeDate = moment(inputDate)
  if(seriesType == 'DL'){ //Daily
    beforeDate = beforeDate.add(1, 'days')
  }
  else if(seriesType == 'WL'){ //Weekly
    beforeDate = beforeDate.add(7, 'days')
  }
  else if(seriesType == 'FN'){ //FortNightly
    beforeDate = beforeDate.add(14, 'days')
  }
  else if(seriesType == 'ML'){ //Monthly
    beforeDate = beforeDate.add(1, 'month')
  }
  else if(seriesType == '3M'){ //3 Months
    beforeDate = beforeDate.add(3, 'month')
  }
  else if(seriesType == '6M'){ //6 Months
    beforeDate = beforeDate.add(6, 'month')
  }
  else if(seriesType == 'YL'){ //Yearly
    beforeDate = beforeDate.add(1, 'year')
  }
  return new Date(beforeDate)
}

export function getUpdatedReminderDate(currentDue, currentReminder, updatedDue){
  //console.log('currentDue',currentDue)
  //console.log('currentReminder',currentReminder)
  const currentDueTemp = new Date(currentDue).getTime()
  const currentReminderTemp = new Date(currentReminder).getTime()
  const diffInTime = currentDueTemp - currentReminderTemp
  let beforeDate = moment(updatedDue)
  //console.log('beforeDate 0:',beforeDate)
  beforeDate = beforeDate.add(-diffInTime, 'milliseconds')
  //console.log('beforeDate 1:',beforeDate)
  return new Date(beforeDate)
}

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
  return hDisplay + mDisplay + sDisplay; 
}

export function showSuccessToast(msg) {
    toastr.options = {
        positionClass: "toast-top-right",
        timeOut: 3000,
        closeButton: false,
        debug: false,
        progressBar: false,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000
    }
    toastr.success(msg, 'Success')
}

export function showCloseableToast(message, position,toastType) {
    toastr.options = {
        positionClass: "toast-"+position, //top/bottom-left/right/center/full-width, 
        timeOut: 5000,
        closeButton: true,
        debug: false,
        progressBar: true,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000
    }
    if (toastType === "info") toastr.info(message, "Info")
    else if (toastType === "warning") toastr.warning(message, "Warning")
    else if (toastType === "error") toastr.error(message, "Alert")
    else toastr.success(message, "Success")
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function isLoggedIn(){
  if(localStorage.getItem('authUser')){
    return true
  }
  else{
    return false
  }
}

const priorityOrder = {
  'H':1,
  'N':2,
  'L':3
}

export const sortTasks = (tasks, option) =>{
  //console.log('tasks before:',tasks)
  if(option == 'Due Date'){
    const sortedArray = tasks.sort((a, b) => {
      //console.log('a.due::',a.due)
      let firstDate = moment(a.due).format('YYYYMMDDHH24MMSS')
      if(!a.due){
        firstDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      let secondDate = moment(b.due).format('YYYYMMDDHH24MMSS')
      if(!b.due){
        secondDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      return firstDate - secondDate
    })
    return sortedArray
  }
  else if(option == 'Reminder Date'){
    const sortedArray = tasks.sort((a, b) => {
      //console.log('a.due::',a.due)
      let firstDate = moment(a.remnds).format('YYYYMMDDHH24MMSS')
      if(!a.remnds){
        firstDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      let secondDate = moment(b.remnds).format('YYYYMMDDHH24MMSS')
      if(!b.remnds){
        secondDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      return firstDate - secondDate
    })
    return sortedArray
  }
  else if(option == 'Created Date'){
    const sortedArray = tasks.sort((a, b) => (a.id) - (b.id))
    return sortedArray
  }
  else if(option == 'Priority'){
    const sortedArray = tasks.sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority])
    return sortedArray
  }
}

export function SetS3Config(level,bucket){
  if(bucket == undefined || bucket === ''){
    bucket = process.env.REACT_APP_BUCKET_NAME
  }
  Storage.configure({ 
      bucket: bucket,
      level: level,
      region: process.env.REACT_APP_REGION,  
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  });
}

export const getURLReplacedContent = str => {

  var m,
  urls = [],
  rex = /(img src=\"https?:\/\/[^"]*)/g;

  //m = rex.exec( str )

  let counter = 1;
  while ( m = rex.exec( str ) ) {
    urls.push( m[1] );
    counter++
    if(counter > 100){
      console.log('breaking..')
      break
    }
  }
  urls.forEach(each =>{
    if(each.indexOf(UPLOAD_PREFIX_FOLDER) > 0){
      let extracted = each.substring(each.indexOf(UPLOAD_PREFIX_FOLDER), each.indexOf("?"))
      str = str.replace(each,"img src=\"https://"+extracted)
    }
  })
  return str
}

export function ckImageUploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

//Adapter to upload the image to S3 and give the presigned URL
function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          SetS3Config("public");
          //let compressedImage = compressImageFile(file)
          compressImageFile(file).then(compressed => {
            //console.log('UPLOAD_PREFIX_FOLDER:'+UPLOAD_PREFIX_FOLDER)
            Storage.put(UPLOAD_PREFIX_FOLDER+"/"+compressed.name, compressed, { contentType: compressed.type })
            .then(result => {
            getImageURL(result.key).then(response =>{
              resolve({
                default: response
              });
            })
            })
            .catch(err => {
              console.log('err...:',err)
            });
          })
        });
      });
    }
  };
}

/** Get pre signed URL */
async function getImageURL(fileName){
  const resp = await Storage.get(fileName, {
    //level?: private | protected | public, // defaults to `public`
    //identityId?: string, // id of another user, if `level: protected`
    //download?: boolean, // defaults to false
    expires: 2 * 60 * 60, // validity of the URL, in seconds. defaults to 900 (15 minutes)
    //contentType?: string // set return content type, eg "text/html"
  });
  return resp
}

export function addDueAndReminderExpiryFlags(tasks){
  tasks = tasks.map(each => {
    return {
      ...each,
      isDueOver:isPastTime(each.due),
      isReminderOver:isPastTime(each.remnds)
    }
  })
  return tasks
}

export function isPastTime(dateToCheck){
  
  if(dateToCheck == undefined || dateToCheck == null || dateToCheck == ''){
    return false
  }

  const now = new Date().getTime()
  const selectedTime = new Date(dateToCheck).getTime()
  if(now < selectedTime){
    return false
  }
  else{
    return true
  }
}

export const compressImageFile = async (image) => {
  var options = {
    maxSizeMB: 1,
  }
  try {
    const compressedFile = await imageCompression(image, options);
    //console.log('compressedFile 0',compressedFile)
    return compressedFile
  } catch (error) {
    console.log(error);
  }
}