import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { 
  Card, 
  CardBody, 
  Col, 
  Container, 
  Media, 
  Row,
  CardHeader,
  Badge,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from "reactstrap"

import NewTask from "./NewTask";
import TopBar from "./TopBar"
import PlaceHolder from "./PlaceHolder"
import NoTaskBanner from "./NoTaskBanner"

import { getAllTasksCache } from "helpers/cache_helper"
import SimpleBar from "simplebar-react"
import * as moment from "moment"
import { repOptions } from "common/data/tasks"
import { deleteTask } from "helpers/backend_helper"
import { editTask } from "helpers/backend_helper"
import { markAsDone } from "helpers/backend_helper"
import { showSuccessToast } from "helpers/function_helper"
import { getNextSeries } from "helpers/function_helper"
import { getUpdatedReminderDate, addDueAndReminderExpiryFlags} from "helpers/function_helper"

import SweetAlert from "react-bootstrap-sweetalert"

const TaskList = () => {

  const [clickAddNewTask, setClickAddNewTask] = useState(false)
  const [tasksLoading, setTasksLoading] = useState(false)
  const [noTasks, setNoTasks] = useState(false)
  const [rawTasks, setRawTasks] = useState([])
  const [processedTasksMap, setProcessedTasksMap] = useState()
  const [editMode, setEditMode] = useState(false)
  const [taskToEdit, setTaskToEdit] = useState()
  const [taskToDelete, setTaskToDelete] = useState()
  const [inProgress, setInProgress] = useState(false)
  const [showDeletePopUp, setShowDeletePopUp] = useState(false)
  const [curatedTasks, setCuratedTasks] = useState([])

  const priorityColourMap = {
    "N":"text-warning",
    "H":"text-danger",
    "L":"text-info"
  }
  const addNewTask = () => {
    setClickAddNewTask(true)
    setEditMode(false)
  }

  const closeNewTask = (newTaskAdded) => {
    setClickAddNewTask(false)
    if(newTaskAdded){
      getAllTasks('P',false) //P for pending
    }
  }

  useEffect(()=>{
    getAllTasks('P',true) //P for pending
  },[])

  useEffect(()=>{
    processTasks()
  },[curatedTasks])

  const onUpdateTask = tasks => {
    setCuratedTasks(tasks)
  }

  const processTasks = () =>{
    if(curatedTasks){
      const taskMap = new Map();
      let processedTasks = {}
      curatedTasks.map(each =>{
        let currentCat = 'Default'
        if(each.cat && each.cat != ''){
          currentCat = each.cat
        }

        if(taskMap.has(currentCat)){
          const tempArray = taskMap.get(currentCat)
          tempArray.push(each)
          taskMap.set(currentCat,tempArray)
        }
        else{
          const tempArray = [each]
          taskMap.set(currentCat,tempArray)
        }
      })
      setProcessedTasksMap(taskMap)
    }
  }

  async function getAllTasks(status, fromCache){
    //console.log('getting all tasks..')
    setTasksLoading(true)
    setNoTasks(false)
    try {
      let resp = await getAllTasksCache(status,fromCache)
      if(resp){
        setCuratedTasks([])
        resp = addDueAndReminderExpiryFlags(resp)
        setRawTasks(resp)
        if(resp.length == 0){
          setNoTasks(true)
        }
      }
      setTasksLoading(false)
    } catch (error) {
      setTasksLoading(false)
      console.log('error:',error)
    }
  }

  function getDisplayValueForRepeatOption(rptVal){
    //console.log('rptVal',rptVal)
    if(rptVal){
      const foundOption = repOptions.filter(each => each.value == rptVal)
      //console.log('foundOption',foundOption)
      if(foundOption && foundOption.length == 1){
        return foundOption[0].label
      }
    }
  }
  const formatDateAndTime = date => {
    return moment(new Date(date)).format("DD MMM Y, h:mm a")
  }

  const updateTaskWithNextSeries = task =>{
    if(task && task.due && task.rpt && task.rpt != 'NR'){
      let updatedReminderDate;
      const updatedDueDate = getNextSeries(task.due, task.rpt)
      if(task.remnds){
        updatedReminderDate = getUpdatedReminderDate(task.due,task.remnds,updatedDueDate)
      }
      let mofifiedTask = {
        ...task,
        due: updatedDueDate,
        remnds:updatedReminderDate
      }
      return mofifiedTask
    }
    return task
  }

  const handleDone = task => {
    if(task && task.rpt && task.rpt != 'NR'){
      //repeatable task - need to set to next date
      setInProgress(true)
      editTask(updateTaskWithNextSeries(task))
      .then(resp=>{
        if(resp && resp.Message && resp.Message=='SUCCESS'){
          getAllTasks('P',false)
          showSuccessToast('Task Marked as Done')
        }
      })
      .finally(() => {
        setInProgress(false)
      })
    }
    else{
      handleFullyDone(task)
    }
  }

  const handleFullyDone = task => {
    setInProgress(true)
    markAsDone(task)
    .then(resp=>{
      //console.log('resp::'+JSON.stringify(resp))
      if(resp && resp.Message && resp.Message=='SUCCESS'){
        getAllTasks('P',false)
        showSuccessToast('Task Marked as Done')
      }
    })
    .finally(() => {
      setInProgress(false)
    })
  }

  const handleEdit = task => {
    setEditMode(true)
    setTaskToEdit(task)
    setClickAddNewTask(true)
  }

  const handleDelete = task => {
    setTaskToDelete(task)
    setShowDeletePopUp(true)
  }

  const confirmDelete = async () =>{
    setShowDeletePopUp(false)
    setInProgress(true)
    deleteTask({
      id:taskToDelete.id,
      status: taskToDelete.status
    })
    .then(resp=>{
      //console.log('resp::'+JSON.stringify(resp))
      if(resp && resp.Message && resp.Message=='SUCCESS'){
        getAllTasks('P',false)
        showSuccessToast('Task deleted successfully')
      }
    })
    .finally(() => {
      setInProgress(false)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tasks |  MyNotes - PepMash</title>
        </MetaTags>
        <Container fluid>
          <h4>Tasks</h4>
          {tasksLoading && <PlaceHolder/> }
          {!tasksLoading && !noTasks && <TopBar 
            rawTasks={rawTasks} 
            onUpdateTask={onUpdateTask}
            addNewTask={addNewTask}
          />}
          {noTasks && <NoTaskBanner addNewTask={addNewTask}/>}
          <Row className="mt-3">
            {
              processedTasksMap && [...processedTasksMap.values()].map((tasks,idx) =>(
                <Col md="4" sm="12" key={idx}>
                  <Card>
                    <CardHeader className="bg-transparent border-bottom">
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="me-2">
                          <h5 className="card-title mt-1 mb-0">{(tasks[0].cat && tasks[0].cat != '') ? tasks[0].cat : 'Default'}</h5>
                        </div>
                        <div className="mt-1 mb-0">
                          {inProgress ? 
                          <Spinner style={{ width: '1.0rem', height: '1.0rem' }} className="" color="info" /> 
                          : 
                          <><span>{tasks.length}</span> <span className="font-size-13">{tasks.length > 1 ? "tasks":"task"}</span></>
                          }
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="padding-x-less">
                      <SimpleBar style={{ maxHeight: "400px" }}>
                        <ul className="list-group list-group-flush padding-bottom-100">
                        {tasks.map((task, innerIndx) => (
                          <li className={tasks.length > 1 ? "list-group-item py-3" : "list-group-item py-3 "} key={innerIndx}>
                            <Media>
                              <div className="me-2">
                                <div className="avatar-sm me-3">
                                  <span className={"avatar-title rounded-circle bg-warning bg-soft font-size-22 "+priorityColourMap[task.priority]}>
                                    <i className="bx bxs-flag" />
                                  </span>
                                </div>
                              </div>
                              <Media body className="align-self-center">
                                <div className="text-muted">
                                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <h5>{task.title}</h5>
                                    {task.isDueOver && <Badge className="bg-danger badge font-size-12 me-2 px-1 py-1">
                                      {"Overdue"}
                                    </Badge> }
                                  </div>
                                  {task.due && 
                                  <p className="mb-1">
                                    <i className="bx bx-calendar me-1"/>{formatDateAndTime(task.due)}
                                    {task.rpt && 
                                    <span>
                                      {" | "}{getDisplayValueForRepeatOption(task.rpt)}
                                    </span>}
                                  </p>
                                  }
                                  {task.remnds && 
                                  <p className="mb-1">
                                    <i className={task.isReminderOver ?"bx bxs-bell-ring text-danger bx-tada me-1":"bx bxs-bell-ring me-1"}/>{formatDateAndTime(task.remnds)}
                                  </p>
                                  }
                                </div>
                              </Media>
                              <div>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    className="card-drop"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18 pointer-cursor" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                      onClick={() => handleDone(task)}
                                    >
                                      <i className="mdi mdi-check-bold font-size-16 text-success me-1" />{" "}
                                      Done
                                    </DropdownItem>
                                    {task.rpt && task.rpt != 'NR' && <DropdownItem
                                      onClick={() => handleFullyDone(task)}
                                    >
                                      <i className="mdi mdi-check-all font-size-16 text-success me-1" />{" "}
                                      Done (Series)
                                    </DropdownItem>
                                    }
                                    <DropdownItem
                                      onClick={() => handleEdit(task)}
                                    >
                                      <i className="mdi mdi-pencil font-size-16 text-info me-1" />{" "}
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => handleDelete(task)}
                                    >
                                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </Media>
                            {task.tags && task.tags.length > 0 && <div className="align-self-center overflow-hidden me-auto mt-3 ms-3">
                             {task.tags.map((eachTag,tIdx) => (
                              <Badge color={""}
                                className={
                                  "bg-info badge badge-warning font-size-12 me-1 px-2 py-1"
                                }
                                key={tIdx}
                              >
                                {eachTag}
                              </Badge>
                            ))}
                            </div>}

                          </li>
                          ))}
                        </ul>
                      </SimpleBar>
                    </CardBody>
                  </Card>
                </Col>
              ))
            }
          </Row>

          <NewTask 
            clickAddNewTask={clickAddNewTask} 
            closeNewTask={closeNewTask}
            taskToEdit={taskToEdit}
            editMode={editMode}
          />

          {showDeletePopUp ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                confirmDelete()
              }}
              onCancel={() => {
                setShowDeletePopUp(false)
              }}
            >
              <p>Deleting Task : <b>{taskToDelete.title}</b></p>
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TaskList;
