import axios from "axios"
import { del, get, post, put, postImage, postArray } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

export const getConfigs = async (data) => {
  return post(url.GET_CONFIG, data)
}

export const getScratchPad = async (data) => {
  return post(url.GET_SCRATCHPAD, data)
}

export const updateScratchPad = async (data) => {
  return post(url.UPDATE_SCRATCHPAD, data)
}

export const getNotes = async (data) => {
  return post(url.GET_NOTES, data)
}

export const updateNotes = async (data) => {
  return post(url.UPDATE_NOTES, data)
}
export const deleteNote = async (data) => {
  return post(url.DELETE_NOTE, data)
}

export const updateTitle = async (data) => {
  return post(url.UPDATE_TITLE, data)
}

export const getAllTasks = async (data) => {
  return post(url.GET_ALL_TASKS, data)
}

export const addConfigs = async (data) => {
  return post(url.ADD_CONFIG, data)
}

export const addTask = async (data) => {
  return post(url.ADD_TASK, data)
}

export const editTask = async (data) => {
  return post(url.EDIT_TASK, data)
}

export const deleteTask = async (data) => {
  return post(url.DELETE_TASK, data)
}

export const markAsDone = async (data) => {
  return post(url.MARK_AS_DONE, data)
}

export const getStoreDetails = async (data) => {
  return post(url.GET_STORE_DETAILS, data)
}

export const getLeaderBoard = async (data) => {
  return post(url.GET_LEADER_BOARD, data)
}

export const postGPCompletion = async (data) => {
  return post(url.ADD_GP_COMPLETION, data)
}

export const postSudokuCompletion = async (data) => {
  return post(url.ADD_SUDOKU_COMPLETION, data)
}

const postImages = async (data) => {
  const resp = await postImage(url.POST_IMAGES, data)
  .then(response => response)
  return resp;
  //return postImage(url.POST_IMAGES, data)
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postImages
}
