import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
 } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const DueDatePicker = props => {

  const [menu, setMenu] = useState(false)
  const [greaterThanCurrent, setGreaterThanCurrent] = useState(true)
  const [selectedDate, setSelectedDate] = useState('')
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
  const [dueDate, setDueDate] = useState()
  
  useEffect(()=>{
    //console.log('props.dueDate',props.dueDate)
    //console.log('props.editMode',props.editMode)
    if(props.dueDate && props.editMode){
      setDueDate(new Date(props.dueDate))
    }
  },[props.dueDate])

  function setSelectedOption(option){
    setSelectedDate(option)
    const tempDate = dueDate ? new Date().setHours(dueDate.getHours(),dueDate.getMinutes(),0,0) : new Date().setHours(17,0,0,0)
    let todayStart = moment(tempDate)
    //let startD = todayStart.format("yyyy-MM-DD")
    if(option == 'Today'){
      //Nothing - already defaulted to today
    }
    else if(option == 'Tomorrow'){
      todayStart = todayStart.add(1, 'days')
    }
    else if(option == 'Next Friday'){
      todayStart = setDay(new Date(tempDate),5)
      //todayStart = todayStart.startOf('week').format("yyyy-MM-DD")
    }
    else if(option == 'Next Monday'){
      todayStart = setDay(new Date(tempDate),1)
    }
    else if(option == 'In a Week'){
      todayStart = todayStart.add(7, 'days')
    }
    else if(option == 'In a Month'){
      todayStart = todayStart.add(1, 'month')
    }
    const tempFinalDate = new Date(todayStart)
    checkGreaterThanNow(tempFinalDate)
    setDueDate(tempFinalDate)
    setMenu(!menu)

    props.onConfirmDueDate(tempFinalDate)
  }

  function handleDateSelection(dateInput){
    setDueDate(dateInput)
    checkGreaterThanNow(dateInput)
    props.onConfirmDueDate(dateInput)
  }

  const handleValidDate = date => {
    if(date){
      return moment(new Date(date)).format("DD MMM Y, h:mm a")
    }
    else{
      return ''
    }
  }

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }

  const confirmDates = () => {
    setSelectedDate('Custom')
    setMenu(!menu)
    props.onConfirmDueDate(dueDate)
  }

  function setDay(date, dayOfWeek) {
    date = new Date(date.getTime());
    date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7);
    return date;
  }

  function checkGreaterThanNow(dateToCheck){
    //console.log('dateToCheck',dateToCheck)
    const now = new Date().getTime()
    const selectedTime = new Date(dateToCheck).getTime()
    if(now > selectedTime){
      setGreaterThanCurrent(false)
    }
    else{
      setGreaterThanCurrent(true)
    }
  }

  return (
    <React.Fragment>
      <Dropdown
          className=""
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          disabled={false}
        >
          <DropdownToggle className="btn btn-light d-flex align-items-center justify-content-center ">
           <span className={greaterThanCurrent===true ? "text-primary" : "text-danger"}>
             {dueDate ? handleValidDate(dueDate) : 'Add Due Date'}
            </span>
            {dueDate ? 
            <>
              <span className="ms-2" onClick={(e)=> {
                e.stopPropagation()
                setDueDate()
                props.onConfirmDueDate()
                setSelectedDate('')
                }}>
                <i className="bx bx-x-circle font-size-18 text-danger pt-1 ps-1" />
              </span>
              {!greaterThanCurrent && <small className="ms-2 text-warning">Due date is already over.</small>}
            </> : null}
          </DropdownToggle>
          <Row>
            <Col>
              <DropdownMenu className="dropdown-megamenu no-mobile-padding">
                <Nav className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: customIconActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleIconCustom("1")
                      }}
                    >
                      <span className=" ">
                        Presets
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: customIconActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                      <span className=" ">
                        Custom
                      </span>
                      </NavLink>
                    </NavItem>
                </Nav>
                <TabContent
                    activeTab={customIconActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row className="mb-1 mx-1">
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Today'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Today')
                            }}>
                              Today
                          </Link>
                        </Col>
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Tomorrow'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Tomorrow')
                            }}>
                              Tomorrow
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mb-1 mx-1">
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Next Friday'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Next Friday')
                            }}>
                              Next Friday
                          </Link>
                        </Col>
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Next Monday'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Next Monday')
                            }}>
                              Next Monday
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mb-1 mx-1">
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'In a Week'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('In a Week')
                            }}>
                              In a Week
                          </Link>
                        </Col>
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'In a Month'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('In a Month')
                            }}>
                              In a Month
                          </Link>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2" className="text-center">
                      <Row className="mb-1 text-center">
                        <Col sm={8} xs={9} className="text-center">
                          <DatePicker
                            selected={dueDate ? new Date(dueDate) : new Date().setHours(17,0,0,0)}
                            onChange={date=>{
                              handleDateSelection(date)
                            }}
                            minDate={new Date()}
                            inline
                            className="form-control border-bottom"
                            placeholderText="Select date"
                            dateFormat="yyyy-MM-DD"
                          />
                        </Col>
                        <Col sm={4} xs={3} className="text-center">
                            <DatePicker
                              selected={dueDate ? new Date(dueDate) : new Date().setHours(17,0,0,0)}
                              onChange={date=>{
                                handleDateSelection(date)
                              }}
                              inline
                              minDate={new Date()}
                              showTimeSelect 
                              showTimeSelectOnly
                              className="form-control border-bottom"
                              placeholderText="Select time"
                              dateFormat="h:mm a"
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <Button
                            type="button"
                            color="primary"
                            className="btn btn-primary w-lg mt-2"
                            onClick={() => {
                              confirmDates()
                            }}
                            disabled={dueDate?false:true}
                          >
                            Confirm
                          </Button>
                        </Col>
                      </Row>
                    </TabPane>
                </TabContent>
                
              </DropdownMenu>
            </Col>
          </Row>
        </Dropdown>
      </React.Fragment>
  )
}

DueDatePicker.propTypes = {
  onConfirmDueDate : PropTypes.func
}

export default DueDatePicker
