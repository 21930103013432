import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { Auth } from "aws-amplify";

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = "/"
//const API_URL = "https://localhost:8443/"

const dev = {
  //apiUrl : 'http://localhost:8080/'
  apiUrl : 'https://gmwkkemckh.execute-api.ap-south-1.amazonaws.com/prod'
};

const prod = {
  apiUrl : 'https://gmwkkemckh.execute-api.ap-south-1.amazonaws.com/prod'
};

const envConfigs = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

const axiosApi = axios.create({
  //baseURL: API_URL,
  baseURL: envConfigs.apiUrl,
  //baseURL: 'https://flamingo.tekymart.com/',
  //withCredentials: true
})

//axiosApi.defaults.withCredentials = true;

axiosApi.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
   Auth.currentSession()
     .then((session) => {
       var idTokenExpire = session.getIdToken().getExpiration();
       var refreshToken = session.getRefreshToken();
       var currentTimeSeconds = Math.round(+new Date() / 1000);
       if (idTokenExpire < currentTimeSeconds) {
         Auth.currentAuthenticatedUser()
           .then((res) => {
             res.refreshSession(refreshToken, (err, data) => {
               if (err) {
                 Auth.signOut()
               } else {
                 config.headers.common["Authorization"] = data.getIdToken().getJwtToken();
                 resolve(config);
               }
             });
           });
       } else {
         config.headers.common["Authorization"] =  session.getIdToken().getJwtToken();
         resolve(config);
       }
     })
     .catch(() => {
       // No logged-in user: don't set auth header
       resolve(config);
     });
 })
});


axiosApi.interceptors.response.use(
  response => response,
  error => {
    //console.log('error??'+JSON.stringify(error.response.status))
    return Promise.reject(error)
  }
)

function getToken(){
  if(localStorage.getItem("authUser")){
    const obj = JSON.parse(localStorage.getItem("authUser"))
    if(obj?.signInUserSession?.idToken?.jwtToken){
      return obj.signInUserSession.idToken.jwtToken
    }
  }
  return ''
}

export async function get(url, config = {}) {
  //axiosApi.defaults.headers.common["Authorization"] = getToken()
  //axiosApi.defaults.headers.common["Accept"] = "*/*"
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  //axiosApi.defaults.headers.common["Authorization"] = getToken()
  //axiosApi.defaults.headers.common["Accept"] = "*/*"
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postArray(url, data, config = {}) {
  return axiosApi
    .post(url,  data , { ...config })
    .then(response => response.data)
}

export async function postImage(url, data, config = {}) {
  axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data"
  const result = await axiosApi
    .post(url,  data , { ...config })
    .then(response => response.data)
    //console.log('result:'+JSON.stringify(result))
    return result;
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
