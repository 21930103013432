const repOptions = [{
  value:'NR',
  label: 'Never'
},
{
  value:'DL',
  label: 'Daily'
},
{
  value:'WL',
  label: 'Weekly'
},
{
  value:'FN',
  label: 'FortNightly'
},
{
  value:'ML',
  label: 'Monthly'
},
{
  value:'3M',
  label: 'Every 3 months'
},
{
  value:'6M',
  label: 'Every 6 months'
},
{
  value:'YL',
  label: 'Yearly'
}]

export { repOptions }
