import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Badge,
  Card,
  CardBody
 } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"
import SimpleBar from "simplebar-react"
import { getAllTasksCache } from "helpers/cache_helper"
import { sortTasks, addDueAndReminderExpiryFlags } from "helpers/function_helper"
import NewTask from "../Tasks/NewTask";
import NoTaskBanner from "../Tasks/NoTaskBanner"
import FullPagePlaceHolder from "components/Common/FullPagePlaceHolder"

const RawTaskList = props => {

  const sortOptions = ['Due Date','Reminder Date','Created Date','Priority']
  const priorityColourMap = {
    "N":"info",
    "H":"danger",
    "L":"success"
  }

  const [clickAddNewTask, setClickAddNewTask] = useState(false)
  const [menu, setMenu] = useState(false);
  const [tasksLoading, setTasksLoading] = useState(false)
  const [noTasks, setNoTasks] = useState(false)
  const [rawTasks, setRawTasks] = useState([])
  const [sortedTasks, setSortedTasks] = useState([])
  const [sortBy, setSortBy] = useState('Due Date')

  useEffect(()=>{
    getAllTasks('P',true) //P for pending
  },[])

  /*
  useEffect(()=>{
    console.log('sortedTasks',sortedTasks)
  },[sortedTasks])
  */

  async function getAllTasks(status, fromCache){
    setTasksLoading(true)
    setNoTasks(false)
    try {
      let resp = await getAllTasksCache(status,fromCache)
      if(resp){
        if(resp.length == 0){
          setNoTasks(true)
        }
        else{
          resp = addDueAndReminderExpiryFlags(resp)
          setRawTasks(resp)
          setSortedTasks(sortTasks(resp,sortBy))
        }
      }
      setTasksLoading(false)
    } catch (error) {
      setTasksLoading(false)
      console.log('error:',error)
    }
  }

  const changeSorting = option => {
    setSortBy(option)
    setSortedTasks(sortTasks(rawTasks,option))
    setMenu(!menu);
  }

  const formatDateAndTime = date => {
    if(date){
      return moment(new Date(date)).format("DD MMM, h:mm a")
    }
    else{
      return "No Date"
    }
  }

  const toggle = () => {
    setMenu(!menu);
  }

  const closeNewTask = (newTaskAdded) => {
    setClickAddNewTask(false)
    if(newTaskAdded){
      getAllTasks('P',false) //P for pending
    }
  }

  const addNewTask = () => {
    setClickAddNewTask(true)
  }

  return (
    <React.Fragment>
      <Card>
      {tasksLoading && <FullPagePlaceHolder/>}
      {noTasks && <NoTaskBanner addNewTask={addNewTask}/>}
        {!noTasks && <CardBody>
          <div className="d-flex">
            <div className="me-2">
              <h5 className="card-title mb-4">Pending Tasks</h5>
            </div>
            <Dropdown isOpen={menu} toggle={toggle} className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
                type="button"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu direction="left">
                <Link className="dropdown-item disabled" to="#">
                  Sort by
                </Link>
                <div className="dropdown-divider"></div>
                {sortOptions.map((eachItem,i) =>(
                  <Link key={i} className={eachItem == sortBy ? "dropdown-item bg-light-blue":"dropdown-item"} to="#" onClick={()=>changeSorting(eachItem)}>
                    {eachItem}
                  </Link>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <SimpleBar style={{ maxHeight: "450px" }}>
            <div className="mt-2">
              <ul className="verti-timeline list-unstyled">
                {sortedTasks.map((eachTask,i) =>(
                  <li key={i} className={"event-list "+priorityColourMap[eachTask.priority]}>
                    <div className="event-timeline-dot">
                      <i className={i==0? "bx bxs-right-arrow-circle font-size-18 bx-fade-right" : "bx bxs-right-arrow-circle font-size-18 "}></i>
                    </div>
                    <div className="media">
                      <div className="me-3">
                        <h5 className={eachTask.isDueOver ? "font-size-14 text-danger" : "font-size-14"}>
                          {formatDateAndTime(eachTask.due)}
                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                        </h5>
                      </div>
                      <div className="media-body">
                        <div className="">
                          {eachTask.title}
                          {eachTask.tags && eachTask.tags.length > 0 && 
                            <span className="align-self-center overflow-hidden me-auto mt-3 ms-3">
                             {eachTask.tags.map((eachTag,tIdx) => (
                              <Badge color={""}
                                className={
                                  "bg-warning badge badge-warning font-size-12 mb-1 me-1 px-2 py-1"
                                }
                                key={tIdx}
                              >
                                {eachTag}
                              </Badge>
                            ))}
                            </span>}
                        </div>
                      </div>
                      <div>
                      <Badge className="bg-secondary badge badge-warning font-size-12 me-1 px-2 py-1">
                        {eachTask.cat}
                      </Badge>
                      {eachTask.isReminderOver && <i className="bx bxs-bell-ring text-danger font-size-18 bx-tada"></i>}
                        
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </SimpleBar>
          <hr/>
          {!noTasks && <div className="text-center mt-2">
            <Link className="btn btn-primary" to="/tasks">
                  Manage <i className="mdi mdi-arrow-right ms-1"></i>
            </Link>
          </div>}
        </CardBody>}
      </Card>

      <NewTask 
          clickAddNewTask={clickAddNewTask} 
          closeNewTask={closeNewTask}
          editMode={false}
        />
    </React.Fragment>
  )

}

RawTaskList.propTypes = {
}

export default RawTaskList
