import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import AuthCode from "react-auth-code-input"
import { 
  Row, 
  Col, 
  CardBody, 
  Card, 
  Alert, 
  Container,
  Form,
  FormGroup, 
} from "reactstrap"
import { Auth } from "aws-amplify";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"
import VerifyAccount from "./VerifyAccount"
// import images
import profileImg from "../../assets/images/profile-img.png"

import logoImg from "assets/images/logo-n2.png"

const Register = props => {

  const [registerError, setRegisterError] = useState()
  const [inProgress, setInProgress] = useState(false)
  const [maskedEmail, setMaskedEmail] = useState('')
  const [username, setUserName] = useState('')

  const [registerSuccess, setRegisterSuccess] = useState(false)


  // handleValidSubmit
  const handleValidSubmit = async (values) => {
    
    setInProgress(true)
    setRegisterError()
    //console.log('values::'+(values))
    setUserName(values.email)
    const tempName = values.name
    Auth.signUp({
      username: values.email,
      password: values.confirmpassword,
      attributes: {
        name:values.name,
        email:values.email
      },
    })
    .then((user) => {
      setRegisterSuccess(true)
      setInProgress(false)
      //setEmail("");
      //setPassword("");
      //console.log(user)
      //localStorage.setItem("authUser", JSON.stringify(user))
      setMaskedEmail(user.codeDeliveryDetails.Destination)
      //localStorage.setItem("authUser", JSON.stringify(user))
      // props.history.push("/dashboard")
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      if(err.code && err.code=='UsernameExistsException'){
        setRegisterError('This email is already registered.')
      }
      else if(err.code && err.code=='NotAuthorizedException'){
        setRegisterError('Login Failed. Please check your email or password')
      }
      console.log('Error:',JSON.stringify(err));
      setInProgress(false)
    });
    //dispatch(registerUser(values))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | MyNotes</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      {registerSuccess ? <VerifyAccount username={username} maskedEmail={maskedEmail}></VerifyAccount> : 
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="primary-dark bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-warning p-4">
                        <h5 className="text-warning">Free Register</h5>
                        <p>Get your free MyNotes account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="64"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >

                      {registerError && registerError ? (
                        <Alert color="danger"> 
                          <span className="alert-icon"><i class="bx bx-error-circle me-1 font-size-18 align-middle"></i></span>
                          <span className="alert-text align-middle">{registerError}</span>
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          validate={{
                            required: {value: true, errorMessage: 'Please enter a valid email'},
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="Name"
                          type="text"
                          placeholder="Enter Name"
                          validate={{
                            required: {value: true, errorMessage: 'Please enter your name'},
                            pattern: {value: '^[A-Za-z0-9]+$', errorMessage: 'Your name must be composed only with letter and numbers'},
                            minLength: {value: 3, errorMessage: 'Your name must be between 3 and 16 characters'},
                            maxLength: {value: 16, errorMessage: 'Your name must be between 3 and 16 characters'}
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          validate={{
                            required: {value: true, errorMessage: 'Please enter a password'},
                            minLength: {value: 8, errorMessage: 'Password must be between 8 and 32 characters'},
                            pattern: {value: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$', errorMessage: 'Password must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character and minimum 8 character'},
                            maxLength: {value: 32, errorMessage: 'Your name must be between 8 and 32 characters'}
                          }}
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="confirmpassword"
                          label="Confirm Password"
                          type="password"
                          validate={{
                            required: {value: true, errorMessage: 'Please re-type your password'},
                            match:{value:'password', errorMessage: 'Password does not match'},
                            minLength: {value: 8, errorMessage: 'Password must be between 8 and 32 characters'},
                            maxLength: {value: 32, errorMessage: 'Your name must be between 8 and 32 characters'}
                          }}
                          placeholder="Re-type Password"
                        />
                      </div>

                      <div className="mt-4 text-center">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                          disabled={inProgress}
                        >
                          Register
                          {inProgress && <i className="bx bx-loader bx-spin align-middle ms-2"></i>}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the MyNotes{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>

                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} MyNotes.
                  <i className="mdi mdi-heart text-danger" /> PepMash
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      }
    </React.Fragment>
  )
}

export default Register
