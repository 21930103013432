import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Card,
  CardBody
 } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import { sortTasks } from "helpers/function_helper"

const TopBar = props => {

  const [menu, setMenu] = useState(false)
  const [selSortOption, setSelSortOption] = useState('Due Date')
  const [selectedTags, setSelectedTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [currentShownTasks, setCurrentShownTasks] = useState([])

  const sortOptions = ['Due Date','Reminder Date','Created Date','Priority']
  
  const priorityOrder = {
    'H':1,
    'N':2,
    'L':3
  }

  useEffect(()=>{
    const currentTasks = [...props.rawTasks]
    setCurrentShownTasks(...props.rawTasks)
    const foundTags = []
    currentTasks.map(eachTask => {
      if(eachTask.tags && eachTask.tags.length > 0){
        eachTask.tags.map(tag =>{
          if(!foundTags.includes(tag)){
            foundTags.push(tag)
          }
        })
      }
    })
    const allTagsTemp = []
    foundTags.map(each=>{
      allTagsTemp.push({
        label:each,
        value:each
      })
    })
    setAllTags(allTagsTemp)
    //console.log('inside sort..')
    props.onUpdateTask(sortTasks(currentTasks,selSortOption))
  },[props.rawTasks])

  const handleUserClicks = () =>{
    props.addNewTask()
  }

  const changeSortOption = (option) =>{
    setSelSortOption(option)
    const currentTasks = [...currentShownTasks]
    props.onUpdateTask(sortTasks(currentTasks,option))
  }
  const toggle = () => {
    setMenu(!menu)
  }

  function handleMultiTags(selectedMulti) {
    setSelectedTags(selectedMulti)
    //console.log('selectedMulti',selectedMulti)
    const currentTasks = [...props.rawTasks]
    if(selectedMulti == undefined || selectedMulti == null || selectedMulti.length ==0){
      props.onUpdateTask(currentTasks)
      return
    }
    
    const filetedTasks = []
    if(currentTasks && currentTasks != null){
      currentTasks.map(each =>{
        if(each.tags && each.tags.length > 0){
          selectedMulti.map(eachTag=>{
            if(each.tags.includes(eachTag.value)){
              if(!filetedTasks.includes(each)){
                filetedTasks.push(each)
              }
            }
          })
        }
      })
      //console.log('filetedTasks',filetedTasks)
      setCurrentShownTasks(filetedTasks)
      props.onUpdateTask(filetedTasks)
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" md="6" className="d-inline-block">
                  <Select
                    placeholder="Filter"
                    value={selectedTags}
                    onChange={(e) => {
                      handleMultiTags(e)
                    }}
                    options={allTags}
                    classNamePrefix="select2-selection "
                    isMulti={true}
                    id="productcategory"
                  />
                </Col>
                <Col xs="12" md="6" className="d-flex justify-content-between mt-2 mt-md-0 ">
                    <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block me-2">
                      <DropdownToggle className="d-flex align-items-center btn btn-light auto-header-item font-size-13 py-2" tag="button">
                        <i className="bx bx-sort-down me-1 font-size-14"/>{selSortOption}
                      </DropdownToggle>

                      <DropdownMenu className="language-switch dropdown-menu-end">
                        {sortOptions.map((each,i)=>(
                          <DropdownItem
                            key={i}
                            onClick={() => changeSortOption(each)}
                            className={`notify-item ${
                              selSortOption === each ? "active" : "none"
                            }`}
                          >
                            <span className="align-middle">
                              {each}
                            </span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    

                    <Button
                      color="primary"
                      className="font-16 btn btn-light text-sm-end"
                      onClick={handleUserClicks}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      New Task
                    </Button>
                 
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

TopBar.propTypes = {
  onUpdateTask : PropTypes.func,
  addNewTask : PropTypes.func,
}

export default TopBar
