import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { GET_NOTES_TITLES } from "./actionTypes"
import { getNotesTitleSuccessful } from "./actions"

import {
  getConfigCache
} from "../../helpers/cache_helper"

// Is user register successfull then direct plot user in redux.
function* getNotesTitles({ payload: { key, fromCache } }) {
  try {
    
    const response = yield call(getConfigCache, key, fromCache)
    yield put(getNotesTitleSuccessful(response))
    
  } catch (error) {
    //yield put(registerUserFailed(error))
  }
}

export function* watchGetNotesTitles() {
  yield takeEvery(GET_NOTES_TITLES, getNotesTitles)
}

function* NotesSaga() {
  yield all([fork(watchGetNotesTitles)])
}

export default NotesSaga
