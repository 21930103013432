import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import { Auth } from "aws-amplify";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-n2.png"

const Login = props => {
  const dispatch = useDispatch()

  const [rememberMeChecked, setRememberMeChecked] = useState(false)
  const [savedUserId, setSavedUserId] = useState('')
  const [loginError, setLoginError] = useState()
  const [inProgress, setInProgress] = useState(false)

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  useEffect(() => {
    if (localStorage.getItem("rememberMeKey")) {
      const obj = (localStorage.getItem("rememberMeKey"))
      setRememberMeChecked(true)
      setSavedUserId(obj)
    }
  }, [props.success])

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    setInProgress(true)
    setLoginError()
    //console.log('values:'+JSON.stringify(values))

    if(rememberMeChecked){
      localStorage.setItem("rememberMeKey", (values.email))
    }
    else{
      localStorage.removeItem("rememberMeKey")
    }

    Auth.signIn({
      username: (values.email),
      password: values.password,
    })
      .then((user) => {
        //setEmail("");
        //setPassword("");
        setInProgress(false)
        //console.log(user);
        localStorage.setItem("authUser", JSON.stringify(user))
        //localStorage.setItem("userSession", JSON.stringify(user.signInUserSession))
        props.history.push("/dashboard")
      })
      .catch((err) => {
        if(err.code && err.code=='UserNotConfirmedException'){
          setLoginError('Your email id is not verified.')
        }
        else if(err.code && err.code=='NotAuthorizedException'){
          setLoginError('Login Failed. Please check your email or password')
        }
        console.log('Error:',JSON.stringify(err));
        setInProgress(false)
      })
    //dispatch(loginUser(values, props.history))
  }

  function toUsername(email) {
    return email.replace('@', '-at-');
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>MyNotes | PepMash</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="primary-dark bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-warning p-4">
                        <h5 className="text-warning">Welcome Back !</h5>
                        <p>Sign in to continue to MyNotes.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="64"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >

                      {loginError ? 
                      <Alert color="danger"> 
                        <span className="alert-icon"><i class="bx bx-error-circle me-1 font-size-18 align-middle"></i></span>
                        <span className="alert-text align-middle">{loginError}</span>
                      </Alert>
                      : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={savedUserId}
                          className="form-control"
                          placeholder="Enter email"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          checked={rememberMeChecked}
                            onChange={() => {
                              setRememberMeChecked(!rememberMeChecked)
                            }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={inProgress}
                        >
                          <Row>
                            <Col>
                            </Col>
                            <Col>
                            Log In
                            </Col>
                            <Col className="text-end">
                              {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                            </Col>
                          </Row>
                          
                          
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} MyNotes{" "}
                  <i className="mdi mdi-heart text-danger" />{" "}PepMash
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
