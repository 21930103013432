import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Card,
  CardBody
 } from "reactstrap"

const FullPagePlaceHolder = props => {

  return (
    <React.Fragment>
      <Row>
        <Col lg="12" xs="12">
          <Card className="shadow-none border mb-0" aria-hidden="true">
            <CardBody>
                <h5 className="card-title placeholder-glow">
                    <span className="placeholder col-6"></span>
                </h5>
                <p className="card-text placeholder-glow">
                    <span className="placeholder col-7"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-8"></span>
                    <span className="placeholder col-7"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-8"></span>
                </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

FullPagePlaceHolder.propTypes = {
}

export default FullPagePlaceHolder
