import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux"
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  Container,
  Spinner
 } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"
import Editable from "react-bootstrap-editable"
import { v4 as uuidv4 } from 'uuid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { getNotesFromCache } from "helpers/cache_helper"

import { updateNotes, updateTitle, deleteNote } from "helpers/backend_helper"
import { getNotesTitles } from "../../store/actions"
import Loader from "components/Common/Loader"
import FullPagePlaceHolder from "components/Common/FullPagePlaceHolder"
import SweetAlert from "react-bootstrap-sweetalert"
import { getURLReplacedContent, ckImageUploadPlugin } from "helpers/function_helper"

const ViewNote = props => {
  const dispatch = useDispatch()
  const editorConfiguration = {
    //toolbar: [ 'bold', 'italic' ]
    toolbar: {
      items: [
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'findAndReplace',
        'fontBackgroundColor',
        'fontColor',
        'fontFamily',
        'fontSize',
        'horizontalLine',
        'highlight',
        'imageInsert',
        'strikethrough',
        'todoList',
        'underline',
        'specialCharacters'
      ]
    },
    language: 'en',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    },
    placeholder: "Start writing...",
    extraPlugins: [ckImageUploadPlugin]
  };

  const [title, setTitle] = useState('Title')
  const [id, setId] = useState()
  const [serverNotes, setServerNotes] = useState()
  const [ckEditor, setCkEditor] = useState()
  const [editMode, setEditMode] = useState(false)
  const [fetchInProgress, setFetchInProgress] = useState(false)
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [showDeletePopUp, setShowDeletePopUp] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  
  const {
    match: { params },
  } = props

  useEffect(() => {
    //console.log('params:',params)
    if (params && params.id) {
      setEditMode(false)
      getNotesFromServer(params.id,true,true)
    } 
  }, [params.id])

  async function getNotesFromServer(input,fromCache,withLoader){
    if(withLoader){
      setFetchInProgress(true)
    }
    getNotesFromCache(input,fromCache)
    .then(resp => {
      //console.log('resp inside comp:',resp)
      //const resp = await getNotes(input)
      if(resp && resp.data){
        setTitle(resp.data.title)
        setId(resp.data.id)
        setServerNotes(resp.data.content)
      }
      setFetchInProgress(false)
    })
    
  }

  const validateTitle = (value ) => {
    if(value == ''){
      return "Please enter a valid title"
    }
  }

  const saveTitle = async (value ) => {
    setTitle(value)
    const resp = await updateTitle({
      id: id,
      title:value
    })
    if(resp && resp.Message == 'SUCCESS'){
      dispatch(getNotesTitles('notes', false))
      const newNote = {
        id:id,
        title:value,
        content:serverNotes
      }
      const respNotes = await updateNotes(newNote)
      if(respNotes && respNotes.Message == 'SUCCESS'){
        getNotesFromServer(id,false,false)
      }
      //console.log('second resp',resp)
    }
  }

  const updateNote = async () => {
    const contentModified = getURLReplacedContent(ckEditor.getData())

    setUpdateInProgress(true)
    const newNote = {
      id:id,
      title:title,
      content:contentModified
    }

    const resp = await updateNotes(newNote)
    //console.log('first resp',resp)
    if(resp && resp.Message == 'SUCCESS'){
      getNotesFromServer(id,false,true)
      //setServerNotes(newNote.content)
      setEditMode(false)
    }
    setUpdateInProgress(false)
  }

  function createMarkup(content) {
    return {__html: content};
  }

  function editContent(){
    setEditMode(true)
  }

  async function confirmDelete(){
    setShowDeletePopUp(false)
    setDeleteInProgress(true)
    const resp = await deleteNote({id:id})
    if(resp && resp.Message == 'SUCCESS'){
      dispatch(getNotesTitles('notes', false))
      setShowDeleteConfirmation(true)
    }
    setDeleteInProgress(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>View Note |  MyNotes - PepMash</title>
        </MetaTags>
        <Container fluid>
        <Loader showLoader={deleteInProgress} />
        {fetchInProgress && <FullPagePlaceHolder/>}
        {!fetchInProgress && <div>
          {!editMode && <Card>
            <CardBody>
              <CardTitle className="ms-0">
                <Row className="">
                  <Col>
                    {title}
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <span onClick={editContent} className="pointer-cursor">
                      <i className="bx bx-edit text-primary font-size-18 pt-1 ps-1" />
                    </span>

                    <span onClick={()=>setShowDeletePopUp(true)} className="ms-2 pointer-cursor">
                      <i className="bx bx-trash text-danger font-size-18 pt-1 ps-1" />
                    </span>

                  </Col>
                </Row>
              </CardTitle>
              <hr/>
                <div className="ck-content" dangerouslySetInnerHTML={createMarkup(serverNotes)} />
            </CardBody>
          </Card>}

          {editMode && 
            <Card>
              <CardBody>
                <CardTitle className="ms-1">
                  <Editable
                      ajax={null}
                      alwaysEditing={false}
                      className="d-flex flex-row"
                      disabled={false}
                      editText="Edit"
                      id={"newnoteid"}
                      initialValue={title}
                      isValueClickable={true}
                      label={null}
                      mode="inline"
                      onSubmit={null}
                      options={null}
                      placement="top"
                      renderCancelElement={null}
                      renderConfirmElement={null}
                      showText
                      type={"textfield"}
                      validate={(value) => {
                        return validateTitle(value)
                      }}
                      onValidated={(validValue) => {
                        saveTitle(validValue)
                        //console.log(`Valid Value: ${validValue}`);
                      }}
                    />
                </CardTitle>
                <hr/>
                <CKEditor
                    editor={ Editor }
                    config={ editorConfiguration }
                    data={serverNotes}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log( 'Editor is ready to use!', editor );
                        setCkEditor(editor)
                    } }
                    onChange={ ( event, editor ) => {
                        //const data = editor.getData();
                        //console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor.getData() );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
              </CardBody>
              <CardFooter className="bg-transparent border-top">
                <div className="text-center">
                  <Link to="#" onClick={()=>setEditMode(false)} className="btn btn-secondary me-2 w-md">
                    Cancel
                  </Link>
                  <Link to="#" disabled={updateInProgress} onClick={updateNote} className="btn btn-primary me-2 w-md">
                    {updateInProgress ? <Spinner style={{ width: '1.0rem', height: '1.0rem' }} className="" color="info" /> : "Update Note"}
                  </Link>
                </div>
              </CardFooter>
            </Card>
            }
          </div>}
        </Container>
      </div>

      {showDeletePopUp ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDelete()
          }}
          onCancel={() => {
            setShowDeletePopUp(false)
          }}
        >
          <p>Deleting Note : <b>{title}</b></p>
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {showDeleteConfirmation ? (
        <SweetAlert
          title="Success!"
          success
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          onConfirm={() => {
            props.history.push("/dashboard")
          }}
        >
          Notes deleted successfully.
        </SweetAlert>
      ) : null}

    </React.Fragment>
  )
}

ViewNote.propTypes = {
  history: PropTypes.object,
}
export default withRouter(ViewNote)
