export const GET_CONFIG = "/getconfig"
export const ADD_CONFIG = "/addconfig"
export const ADD_TASK = "/addtask"
export const EDIT_TASK = "/updatetask"
export const DELETE_TASK = "/deletetask"
export const MARK_AS_DONE = "/updatedone"
export const GET_ALL_TASKS = "/getalltasks"
export const GET_SCRATCHPAD = '/getscratchpad';
export const UPDATE_SCRATCHPAD= '/updatescratchpad';
export const GET_NOTES = '/getnotes';
export const UPDATE_NOTES= '/updatenotes';
export const UPDATE_TITLE= '/updatetitle';
export const DELETE_NOTE= '/deletenote';


export const GET_STORE_DETAILS = "/tme/store-details"
export const GET_LEADER_BOARD = "/mw/get-leaderboard"
export const ADD_GP_COMPLETION = "/mw/gp-done"
export const ADD_SUDOKU_COMPLETION = "/mw/sudoku-done"
export const POST_IMAGES = "/mw/attach-images"

export const UPLOAD_PREFIX_FOLDER = "uploads";