import React, {useEffect} from "react"
import PropTypes from 'prop-types'

const Loader = props => {

  useEffect(() => {
    if (props.showLoader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [props.showLoader])

  return (
    <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      </React.Fragment>
  )
}

Loader.propTypes = {
  showLoader: PropTypes.bool,
}

export default Loader
