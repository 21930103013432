import {
  GET_NOTES_TITLES,
  GET_NOTES_TITLES_SUCCESS
} from "./actionTypes"

export const getNotesTitleSuccessful = titles => {
  return {
    type: GET_NOTES_TITLES_SUCCESS,
    payload: titles,
  }
}

export const getNotesTitles = (key,fromCache) => {
  return {
    type: GET_NOTES_TITLES,
    payload: {key, fromCache},
  }
}
