import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Card,
  CardBody,
  CardTitle,
  Form
 } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { getScratchPadCache } from "helpers/cache_helper"
import { updateScratchPad } from "helpers/backend_helper"

const ScratchPad = props => {

  const editorConfiguration = {
    //toolbar: [ 'bold', 'italic' ]
    toolbar: {
      items: [
      'todoList',
      'bold',
      'italic',
      'bulletedList',
      'numberedList',
      'highlight',
      ]
    },
    language: 'en',
    placeholder: "Start..."
  };

  const [scratchLoading, setScratchLoading] = useState(false)
  const [currentScratchContent, setCurrentScratchContent] = useState('')

  const [ckEditor, setCkEditor] = useState()

  useEffect(()=>{
    //getScratchPad(true)
  },[])

  async function getScratchPad(fromCache){
    setScratchLoading(true)
    try {
      const resp = await getScratchPadCache(fromCache)
      //console.log('resp:',resp)
      if(resp != undefined){
        setCurrentScratchContent(resp)
        if(ckEditor){
          ckEditor.setData(resp)
        }
      }
      setScratchLoading(false)
    } catch (error) {
      setScratchLoading(false)
      console.log('error:',error)
    }
  }

  async function updateScratch(contentInp){
    try {
      if(currentScratchContent.localeCompare(contentInp) !=0){
        const resp = await updateScratchPad({
          content:contentInp
        })
        if(resp){
          getScratchPad(false)
        }
      }
    } catch (error) {
      console.log('error:',error)
    }
  }

  return (
    <React.Fragment>
      <Card >
        <CardBody>
          <CardTitle className="h5 mb-3">Scratchpad</CardTitle>
          
          <CKEditor
              editor={ Editor }
              config={ editorConfiguration }
              data={currentScratchContent}
              onReady={ editor => {
                setCkEditor(editor)
                getScratchPad(true)
                  // You can store the "editor" and use when it is needed.
                  //console.log( 'Editor is ready to use!', editor );
              } }
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  //console.log( { event, editor, data } );
              } }
              onBlur={ ( event, editor ) => {
                 //console.log( 'Blur.', editor.getData() );
                 updateScratch(editor.getData())
              } }
              onFocus={ ( event, editor ) => {
                  //console.log( 'Focus.', editor );
              } }
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ScratchPad.propTypes = {
}

export default ScratchPad
