import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col
} from "reactstrap"

import ScratchPad from "components/Common/ScratchPad"
import RawTaskList from "./RawTaskList"

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard |  MyNotes - PepMash</title>
          </MetaTags>
          <Container fluid>
            <h4>Dashboard</h4>
            <Row>
              <Col xs="12" md="6">
                <RawTaskList/>
              </Col>
              <Col  xs="12" md="6">
                <ScratchPad/>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
