import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux"
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  Container,
  Spinner
 } from "reactstrap"

import { withRouter, Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"
import Editable from "react-bootstrap-editable"
import { v4 as uuidv4 } from 'uuid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { addConfigs } from "helpers/backend_helper"
import { getNotes } from "helpers/backend_helper"
import { updateNotes } from "helpers/backend_helper"
import { getNotesTitles } from "../../store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import Storage from "@aws-amplify/storage";
import { showSuccessToast, getURLReplacedContent, SetS3Config, ckImageUploadPlugin, compressImage } from "helpers/function_helper"
import {UPLOAD_PREFIX_FOLDER} from "helpers/url_helper"


const NewNote = props => {
  const dispatch = useDispatch()
  //const prefixFolder = "uploads";
  /*
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${API_URL}/${res.filename}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }*/

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            //console.log('file',file)
            SetS3Config("public");
            //console.log('UPLOAD_PREFIX_FOLDER:'+UPLOAD_PREFIX_FOLDER)
            Storage.put(UPLOAD_PREFIX_FOLDER+"/"+file.name, file, { contentType: file.type })
              .then(result => {
               getImageURL(result.key).then(response =>{
                resolve({
                  default: response
                });
               })
              })
              .catch(err => {
                console.log('err...:',err)
              });
          });
        });
      }
    };
  }

  async function getImageURL(fileName){
    const resp = await Storage.get(fileName, {
      //level?: private | protected | public, // defaults to `public`
      //identityId?: string, // id of another user, if `level: protected`
      //download?: boolean, // defaults to false
      expires: 2 * 60 * 60, // validity of the URL, in seconds. defaults to 900 (15 minutes)
      //contentType?: string // set return content type, eg "text/html"
    });
    return resp
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const editorConfiguration = {
    //toolbar: [ 'bold', 'italic' ]
    toolbar: {
      items: [
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'findAndReplace',
        'fontBackgroundColor',
        'fontColor',
        'fontFamily',
        'fontSize',
        'horizontalLine',
        'highlight',
        'imageInsert',
        'strikethrough',
        'todoList',
        'underline',
        'specialCharacters'
      ]
    },
    language: 'en',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    },
    placeholder: "Start writing...",
    extraPlugins: [ckImageUploadPlugin]
  };

  const [title, setTitle] = useState('Title')
  const [ckEditor, setCkEditor] = useState()
  const [addInProgress, setAddInProgress] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [id, setId] = useState()

  const validateTitle = (value ) => {
    if(value == ''){
      return "Please enter a valid title"
    }
  }

  const saveTitle = (value ) => {
    setTitle(value)
  }

  const createNewNote = async () => {
    const contentModified = getURLReplacedContent(ckEditor.getData())

    setAddInProgress(true)
    const newNote = {
      id:uuidv4(),
      title:title,
      content:contentModified
    }

    const resp = await updateNotes(newNote)

    if(resp){
      setId(newNote.id)
      const respTitle = await addNoteTitle({
        id:newNote.id,
        title:title,
      })
    }
    setAddInProgress(false)
    showSuccessToast('Notes Created Successfully')
    props.history.push("/viewnote/"+newNote.id)
    //setShowConfirm(true)
  }

  async function addNoteTitle(noteTitle){
    try {
      addConfigs({
        "key":"notes",
        "data": noteTitle
      }).then(resp =>{
        dispatch(getNotesTitles('notes', false))
        //getTags(false)
        //console.log('addTags resp:',resp)
      })
    } catch (error) {
      console.log('error:',error)
    }
  }

  function onConfirmToView(){
    props.history.push("/viewnote/"+id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Note |  MyNotes - PepMash</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle className="ms-1">
                <Editable
                    ajax={null}
                    alwaysEditing={false}
                    className="d-flex flex-row"
                    disabled={false}
                    editText="Edit"
                    id={"newnoteid"}
                    initialValue={title}
                    isValueClickable={true}
                    label={null}
                    mode="inline"
                    onSubmit={null}
                    options={null}
                    placement="top"
                    renderCancelElement={null}
                    renderConfirmElement={null}
                    showText
                    type={"textfield"}
                    validate={(value) => {
                      return validateTitle(value)
                    }}
                    onValidated={(validValue) => {
                      saveTitle(validValue)
                      //console.log(`Valid Value: ${validValue}`);
                    }}
                  />
              </CardTitle>
              <hr/>
              
              <CKEditor
                  editor={ Editor }
                  config={ editorConfiguration }
                  data=""
                  onReady={ editor => {
                      // You can store the "editor" and use when it is needed.
                      //console.log( 'Editor is ready to use!', editor );
                      setCkEditor(editor)
                  } }
                  onChange={ ( event, editor ) => {
                      //const data = editor.getData();
                      //console.log( { event, editor, data } );
                  } }
                  onBlur={ ( event, editor ) => {
                      //console.log( 'Blur.', editor.getData() );
                  } }
                  onFocus={ ( event, editor ) => {
                      //console.log( 'Focus.', editor );
                  } }
              />
            </CardBody>
            <CardFooter className="bg-transparent border-top">
              <div className="text-center">
                <Link to="#" onClick={createNewNote} className="btn btn-primary me-2 w-md">
                  {addInProgress ? <Spinner style={{ width: '1.0rem', height: '1.0rem' }} className="" color="info" /> : "Save New Note"}
                </Link>
              </div>
            </CardFooter>
          </Card>
        </Container>
      </div>
      {showConfirm ? (
        <SweetAlert
          title="Success!"
          success
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          onConfirm={() => {
            onConfirmToView()
          }}
        >
          Notes created successfully.
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

NewNote.propTypes = {
  history: PropTypes.object,
}

export default withRouter(NewNote)
