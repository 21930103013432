import { getConfigs } from "helpers/backend_helper"
import { getAllTasks } from "helpers/backend_helper"
import { getScratchPad } from "helpers/backend_helper"
import { getNotes } from "helpers/backend_helper"
import Storage from "@aws-amplify/storage";
import {UPLOAD_PREFIX_FOLDER} from "helpers/url_helper"

export const getConfigCache = async (key,fromcache) => { 
    
  //console.log('key:|'+key+"|:fromCache?:"+fromcache)
    if(fromcache){
      let jsonResp = localStorage.getItem('config_'+key)
      //console.log('jsonResp:'+jsonResp)
      if(jsonResp){
        return JSON.parse(jsonResp)
      }
    }

    const resp = await getConfigs({key: key})
    //console.log('resp from server:'+resp)
    if(resp?.data){
      localStorage.setItem('config_'+key,JSON.stringify(resp.data))
      return resp.data
    }
    else{
      return null
    }
}

export const getNotesFromCache = async (key,fromcache) => { 
    
  //console.log('notes - key:|'+key+"|:fromCache?:"+fromcache)
    if(fromcache){
      let jsonResp = localStorage.getItem('notes_'+key)
      //console.log('jsonResp:'+jsonResp)
      if(jsonResp){
        return JSON.parse(jsonResp)
      }
    }

    const resp = await getNotes({id:key})
    //console.log('resp from server:'+resp)
    if(resp?.data){
      const respUpdated = await getReplacedContent(resp.data.content)
      let updatedRes  = {
        data:{
          ...resp.data,
          content : respUpdated
        }
      }
      localStorage.setItem('notes_'+key,JSON.stringify(updatedRes))
      return updatedRes
    }
    else{
      return null
    }
}

const getReplacedContent = async (str) => {

    var m,
    urls = [],
    rex = /(img src=\"https?:\/\/[^"]*)/g;

    let counter = 1;
    while ( m = rex.exec( str ) ) {
      urls.push( m[1] );
      counter++
      if(counter > 100){
        console.log('breaking..')
        break
      }
    }

    for(const each of urls){
      if(each.indexOf(UPLOAD_PREFIX_FOLDER) > 0){ //only for direct uploads
        let temp = each.substring(17) //img src="https://
        const signedURL = await getSignedURL(decodeURI(temp))
        str = str.replace('https://'+temp,signedURL)
      }
    }
    return str
}

const getSignedURL = async(inputURL) => {
  const resp = await Storage.get(inputURL, {
    expires: 7*86400, 
  })
  return resp
}

export const getScratchPadCache = async (fromcache) => { 
    
    if(fromcache){
      let jsonResp = localStorage.getItem('scratchpad_')
      //console.log('jsonResp:'+jsonResp)
      if(jsonResp){
        return JSON.parse(jsonResp)
      }
    }

    const resp = await getScratchPad({})
    //console.log('resp from server:'+JSON.stringify(resp))
    //console.log('resp.data from server:'+resp.data)
    if(resp?.data != undefined){
      localStorage.setItem('scratchpad_',JSON.stringify(resp.data))
      return resp.data
    }
    else{
      return null
    }
}

export const getAllTasksCache = async (status, fromcache) => { 
    
    if(fromcache){
      let jsonResp = localStorage.getItem('alltasks_'+status)
      //console.log('jsonResp::'+jsonResp)
      if(jsonResp){
        return JSON.parse(jsonResp)
      }
    }

    const resp = await getAllTasks({
      status: status
    })
    //console.log('resp from server::'+resp)
    if(resp?.data){
      localStorage.setItem('alltasks_'+status,JSON.stringify(resp.data))
      return resp.data
    }
    else{
      return null
    }
}

export const clearLocalCache = async(key) => {
  if(key && key != ''){
    localStorage.removeItem(key)
  }
  else{
    let allkeys = Object.keys(localStorage)
    allkeys.map(each =>{
      //console.log('each:::'+each)
      if(each.startsWith('config_') || each.startsWith('alltasks_') || each.startsWith('scratchpad_') || each.startsWith('notes_')){
        localStorage.removeItem(each)
      }
    })
  }
}
