import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Card,
  CardBody,
  Button
 } from "reactstrap"

import verificationImg from "../../assets/images/verification-img.png"

const NoTaskBanner = props => {

  const onClickTask =()=>{
    props.addNewTask()
  }

  return (
    <React.Fragment>
      <Row className="justify-content-center mt-lg-5">
        <Col xl="5" sm="8">
          <Card>
            <CardBody>
              <div className="text-center">
                <Row className="justify-content-center">
                  <Col lg="10">
                    <h5 className="mt-4 font-weight-semibold">
                      No Pending Tasks Yet
                    </h5>
                    <p className="text-muted fst-italic mt-3">
                      "The secret of getting ahead is getting started."
                    </p>

                    <div className="mt-4">
                      <Button
                        type="button"
                        color="primary"
                        onClick={onClickTask}
                      >
                        Start Noting
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center mt-2 mb-2">
                  <Col sm="6" xs="8">
                    <div>
                      <img
                        src={verificationImg}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

NoTaskBanner.propTypes = {
}

export default NoTaskBanner
