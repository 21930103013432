import React, {useEffect,useState} from "react"
import PropTypes from 'prop-types'
import { 
  Row, 
  Col, 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
 } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import * as moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const ReminderDatePickerV2 = props => {

  const [menu, setMenu] = useState(false)
  const [greaterThanCurrent, setGreaterThanCurrent] = useState(true)
  const [selectedDate, setSelectedDate] = useState('')
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
  const [reminderDate, setReminderDate] = useState()
  const [reminderAfterDue, setReminderAfterDue] = useState(false)
  
  useEffect(()=>{
    //console.log('props.reminderDate',props.reminderDate)
    //console.log('props.editMode',props.editMode)
    if(props.reminderDate && props.editMode){
      setReminderDate(new Date(props.reminderDate))
    }
  },[props.reminderDate])

  function setSelectedOption(option){
    setSelectedDate(option)
    const tempDate = reminderDate ? new Date().setHours(reminderDate.getHours(),reminderDate.getMinutes(),0,0) : new Date().setHours(17,0,0,0)
    let todayStart = moment(tempDate)
    //let startD = todayStart.format("yyyy-MM-DD")
    if(option == 'Today'){
      //Nothing - already defaulted to today
    }
    else if(option == 'Tomorrow'){
      todayStart = todayStart.add(1, 'days')
    }
    else if(option == 'Next Friday'){
      todayStart = setDay(new Date(tempDate),5)
      //todayStart = todayStart.startOf('week').format("yyyy-MM-DD")
    }
    else if(option == 'Next Monday'){
      todayStart = setDay(new Date(tempDate),1)
    }
    else if(option == 'In a Week'){
      todayStart = todayStart.add(7, 'days')
    }
    else if(option == 'In a Month'){
      todayStart = todayStart.add(1, 'month')
    }
    else if(option == '30m'){
      todayStart = moment(props.dueDate)
      todayStart = todayStart.add(-30, 'minute')
    }
    else if(option == '1hr'){
      todayStart = moment(props.dueDate)
      todayStart = todayStart.add(-60, 'minute')
    }
    const tempFinalDate = new Date(todayStart)
    setReminderDate(tempFinalDate)
    setMenu(!menu)

    setReminderAfterDue(checkWhetherAfterDueDate(tempFinalDate))
    props.onConfirmReminderDate(tempFinalDate)
  }

  function handleDateSelection(dateInput){
    setReminderDate(dateInput)
    checkGreaterThanNow(dateInput)
    setReminderAfterDue(checkWhetherAfterDueDate(dateInput))
    props.onConfirmReminderDate(dateInput)
  }

  const handleValidDate = date => {
    if(date){
      return moment(new Date(date)).format("DD MMM Y, h:mm a")
    }
    else{
      return ''
    }
  }

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }

  const confirmDates = () => {
    setSelectedDate('Custom')
    setMenu(!menu)
    setReminderAfterDue(checkWhetherAfterDueDate(reminderDate))
    props.onConfirmReminderDate(reminderDate)
  }

  function setDay(date, dayOfWeek) {
    date = new Date(date.getTime());
    date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7);
    return date;
  }

  useEffect(()=>{
    setReminderAfterDue(checkWhetherAfterDueDate(reminderDate))
  },[props.dueDate])

  function checkWhetherAfterDueDate(inputDate){
    if(props.dueDate && inputDate){
      const dueDateTemp = new Date(props.dueDate).getTime()
      const reminderDateTemp = new Date(inputDate).getTime()
      if(dueDateTemp >= reminderDateTemp){
        return false
      }
      else{
        return true
      }
    }
    else{
      return false
    }
  }

  function checkGreaterThanNow(dateToCheck){
    const now = new Date().getTime()
    const selectedTime = new Date(dateToCheck).getTime()
    if(now > selectedTime){
      setGreaterThanCurrent(false)
    }
    else{
      setGreaterThanCurrent(true)
    }
  }

  /*
  useEffect(()=>{
    if(reminderDate && dueTime){
      const now = new Date().getTime()
      const finalDate = getConsolidatedTime(reminderDate,dueTime)
      const finalDateTime = finalDate.getTime()
      if(now > finalDateTime){
        setGreaterThanCurrent(false)
      }
      else{
        setGreaterThanCurrent(true)
      }
      //props.onConfirmReminderDate(finalDate)
    }
  },[reminderDate,dueTime])
*/

  return (
    <React.Fragment>
      <Dropdown
          className=""
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          disabled={false}
        >
          <DropdownToggle className="btn btn-light d-flex align-items-center justify-content-center ">
           <span className={(!greaterThanCurrent===true || reminderAfterDue ===true)? "text-danger" : "text-primary"}>
             {reminderDate ? handleValidDate(reminderDate) : 'Add Reminder'}
            </span>
            {reminderDate ? 
            <>
              <span className="ms-2" onClick={(e)=> {
                e.stopPropagation()
                setReminderDate()
                }}>
                <i className="bx bx-x-circle font-size-18 text-danger pt-1 ps-1" />
              </span> 
              {!greaterThanCurrent && <small className="ms-2 text-warning">Reminder is already over.</small>}
              {reminderAfterDue && <small className="ms-2 text-warning">Reminder is after due date.</small>}
            </>: null}
          </DropdownToggle>
          <Row>
            <Col>
              <DropdownMenu className="dropdown-megamenu no-mobile-padding">
                <Nav className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: customIconActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleIconCustom("1")
                      }}
                    >
                      <span className=" ">
                        Presets
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: customIconActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                      <span className=" ">
                        Custom
                      </span>
                      </NavLink>
                    </NavItem>
                </Nav>
                <TabContent
                    activeTab={customIconActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      {props.dueDate && <Row className="mb-1 mx-1">
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == '30m'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('30m')
                            }}>
                              30 min before
                          </Link>
                        </Col>
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == '1hr'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('1hr')
                            }}>
                              1 hr before
                          </Link>
                        </Col>
                      </Row>}
                      <Row className="mb-1 mx-1">
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Today'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Today')
                            }}>
                              Today
                          </Link>
                        </Col>
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Tomorrow'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Tomorrow')
                            }}>
                              Tomorrow
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mb-1 mx-1">
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Next Friday'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Next Friday')
                            }}>
                              Next Friday
                          </Link>
                        </Col>
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'Next Monday'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('Next Monday')
                            }}>
                              Next Monday
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mb-1 mx-1">
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'In a Week'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('In a Week')
                            }}>
                              In a Week
                          </Link>
                        </Col>
                        <Col xs={6} className={classNames({"bg-light-blue": selectedDate == 'In a Month'},"py-2 text-center")}>
                          <Link to="#" 
                            className="off-dark "
                            onClick={() => {
                              setSelectedOption('In a Month')
                            }}>
                              In a Month
                          </Link>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2" className="text-center">
                      <Row className="mb-1 text-center">
                        <Col sm={8} xs={9} className="text-center">
                          <DatePicker
                            selected={reminderDate ? new Date(reminderDate) : new Date().setHours(17,0,0,0)}
                            onChange={date=>{
                              handleDateSelection(date)
                            }}
                            minDate={new Date()}
                            inline
                            className="form-control border-bottom"
                            placeholderText="Select date"
                            dateFormat="yyyy-MM-DD"
                          />
                        </Col>
                        <Col sm={4} xs={3} className="text-center">
                            <DatePicker
                              selected={reminderDate ? new Date(reminderDate) : new Date().setHours(17,0,0,0)}
                              onChange={date=>{
                                handleDateSelection(date)
                              }}
                              inline
                              minDate={new Date()}
                              showTimeSelect 
                              showTimeSelectOnly
                              className="form-control border-bottom"
                              placeholderText="Select time"
                              dateFormat="h:mm a"
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <Button
                            type="button"
                            color="primary"
                            className="btn btn-primary w-lg mt-2"
                            onClick={() => {
                              confirmDates()
                            }}
                            disabled={reminderDate?false:true}
                          >
                            Confirm
                          </Button>
                        </Col>
                      </Row>
                    </TabPane>
                </TabContent>
              </DropdownMenu>
            </Col>
          </Row>
        </Dropdown>
      </React.Fragment>
  )
}

ReminderDatePickerV2.propTypes = {
  onConfirmReminderDate : PropTypes.func
}

export default ReminderDatePickerV2
