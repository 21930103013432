import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Auth } from "aws-amplify";
import { useIdleTimer } from 'react-idle-timer'
import {isLoggedIn} from "helpers/function_helper"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"

const ExtendSession = props => {

  const history = useHistory()
  const timeToRefresh = 2 * 10 * 60; //20 minutes / 1200 seconds
  const warningTime = 100; //in seconds
  const maxIdleTime = 60 * 60 * 1000; // 60 minutes
  
  const [showPopUp, setShowPopUp] = useState(false)
  const [timeoutCountdown, setTimeoutCountdown] = useState(warningTime)

  /*
  useEffect(() => {
    //console.log('extend session..')
    const interval = setInterval(() => {
      checkAndRefreshToken()
    }, 1*60*1000); //Checking every minute
    return () => clearInterval(interval);
  }, []);
  */

  async function checkAndRefreshToken(){
    if(isLoggedIn()){
      try {
        if(localStorage.getItem('userSession') && localStorage.getItem('userSession') != null){
          
          const currentSessionFromLocal = JSON.parse(localStorage.getItem('userSession'))
          //console.log('userSession:',currentSessionFromLocal)
          var idTokenExpire = currentSessionFromLocal.idToken.payload.exp
          //console.log('idTokenExpire:',idTokenExpire)
          var currentTimeSeconds = Math.round(+new Date() / 1000);
          //console.log('currentTimeSeconds:',currentTimeSeconds)
          //console.log('time to expire:',(idTokenExpire - currentTimeSeconds))
          if((idTokenExpire - currentTimeSeconds) < timeToRefresh){
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log('extending session now:'+new Date())
            const currentSession = cognitoUser.signInUserSession;
            //console.log('currentSession:',currentSession)
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
              //console.log('Response err:',err)
              //console.log('Response session:',session)
              localStorage.setItem("userSession", JSON.stringify(session))
            });
          }
        }
        else{
          console.log('local storage user session is null..')
        }
      } catch (e) {
        console.log('Exception err:',e)
      }
    }
    else{
      console.log('Not logged in..')
      onCancel()
    }
  }

  const onCancel = () => {
    setTimeoutCountdown(warningTime)
    setShowPopUp(false)
    history.push('/logout')
    //window.location = "/logout"
  }

  const onExtend = () => {
    setTimeoutCountdown(warningTime)
    setShowPopUp(false)
  }

  useEffect(() => {
    if(showPopUp){
      let countDown = warningTime
      const interval = setInterval(() => {
        setTimeoutCountdown(--countDown);
        if(countDown < 1){
          onCancel()
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [showPopUp]);

  /*
  const handleOnIdle = event => {
    console.log('User Idle : last active', getLastActiveTime())
    if(isLoggedIn()){
      if(localStorage.getItem('userSession')){
        const currentSessionFromLocal = JSON.parse(localStorage.getItem('userSession'))
        console.log('userSession:',currentSessionFromLocal)
        var idTokenExpire = currentSessionFromLocal.idToken.payload.exp
        console.log('idTokenExpire:',idTokenExpire)
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        console.log('diff:',(idTokenExpire - currentTimeSeconds))
        if((idTokenExpire - currentTimeSeconds) < 0){ //if it is less than 10 seconds,
          onCancel()
        }
        else{
          if (!showPopUp) {
            setShowPopUp(true)
          }
        }
      }
    }
  }
  */

  const handleOnIdle = event => {
    console.log('User Idle : last active', getLastActiveTime())
    if(isLoggedIn()){
      if (!showPopUp) {
        setShowPopUp(true)
      }
    }
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    console.log('user did something', event)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: maxIdleTime,
    onIdle: handleOnIdle,
    //onActive: handleOnActive,
    //onAction: handleOnAction,
    debounce: 500
  })

  return (
    <React.Fragment>
      
      {showPopUp && <SweetAlert
        showCancel
        confirmBtnText="Stay Connected"
        cancelBtnText="Logout"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        title="Your Session is About to Expire!"
        onCancel={() => {
          onCancel()
        }}
        onConfirm={() => {
          onExtend()
        }}
      >
        Redirecting in {timeoutCountdown} seconds.<br></br>
      </SweetAlert>}
    </React.Fragment>
  )
}

ExtendSession.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

/*
const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}
*/
export default withRouter(ExtendSession)
/*
export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ExtendSession))
)
*/
