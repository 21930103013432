import {
  GET_NOTES_TITLES,
  GET_NOTES_TITLES_SUCCESS
} from "./actionTypes"

const initialState = {
  loading: false,
  notesTitles: [],
}

const NotesTitles = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTES_TITLES:
      state = {
        ...state,
        loading: true
      }
      break
    case GET_NOTES_TITLES_SUCCESS:
      state = {
        ...state,
        loading: false,
        notesTitles: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default NotesTitles
